/**
 * Matomo composable.
 * Handles functions to track app usage in Matomo.
 *
 * Information about Matomo can be found here:
 * - Introduction to JavaScript Tracking Client, including JavaScript example under https://developer.matomo.org/guides/tracking-javascript-guide
 * - JavaScript Tracking API under https://developer.matomo.org/api-reference/tracking-javascript
 */

/* Matomo endpoints. */
const MATOMO_BASE_URL = 'https://analytics.i4mi.bfh.ch/';
const MATOMO_TRACKER_URL = MATOMO_BASE_URL + 'matomo.php';
const MATOMO_SCRIPT = MATOMO_BASE_URL + 'matomo.js';
const MATOMO_SITE_ID = '7';

/* Tracked categories and actions. */
export const EVENT_LANGUAGE = 'language';
export const SELECTOR_SHOWN = 'selectorShown';
export const SELECTOR_CLOSED = 'selectorClosed';
export const APP_LANGUAGE_SELECTED = 'appLanguageSelected';
export const PATIENT_LANGUAGE_SELECTED = 'patientLanguageSelected';
export const VOICE_TYPE_SELECTED = 'voiceTypeSelected';

export const EVENT_CATEGORY = 'category';
export const CATEGORY_SELECTED = 'selectedCategory';

export const EVENT_IMAGE = 'image';
export const IMAGE_SELECTED = 'selectedImage';
export const IMAGE_LINKED_SELECTED = 'selectedLinkedImage';

export const EVENT_SEARCH = 'search';
export const SEARCH_TEXT = 'text';

export const EVENT_TRANSLATE = 'translate';
export const TEXT_TO_TRANSLATE = 'textToTranslate';
export const READ_ALOUD = 'readAloud';

export const EVENT_FEEDBACK = 'feedback';
export const FORM_SHOWN = 'formShown';
export const FORM_CLOSED = 'formClosed';
export const FEEDBACK_SUBMITED = 'feedbackSubmited';

/**
 * Tracks an event to Matomo.
 *
 * @param {string} event - description of the event
 */
export function matomoTrackEvent(category, action, name, value) {
  console.log(`Track event "${category}", "${action}", "${name}", "${value}"`);

  const userId = localStorage.getItem('matomoId'); // we cannot use enum StorageItems.MATOMO_ID here, because it's not TypeScript
  //console.log('Matomo userId: ' + userId);

  var _paq = (window._paq = window._paq || []);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackEvent', category, action, name, value]);
  //_paq.push(['enableLinkTracking']);
  (function () {
    _paq.push(['setTrackerUrl', MATOMO_TRACKER_URL]);
    _paq.push(['setSiteId', MATOMO_SITE_ID]);
    _paq.push(['setUserId', userId]);
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = MATOMO_SCRIPT;
    s.parentNode.insertBefore(g, s);
  })();
}

/**
 * Google Cloud composable
 * Handles functions to call Google Cloud API.
 *
 * About composables: https://vuejs.org/guide/reusability/composables.html
 */

import axios from 'axios';

/**
 * Languages and needed codes for Google Cloud API:
 * - text translation: https://cloud.google.com/translate/docs/languages
 * - speech to text: https://cloud.google.com/text-to-speech; https://cloud.google.com/text-to-speech/docs/voices
 */
const googleAPIlanguages = [
  {
    code: 'ar', // Arabic
    speechCode: 'ar-XA',
    voiceFemale: 'Wavenet-D',
    voiceMale: 'Wavenet-B'
  },
  {
    code: 'de', // German
    speechCode: 'de-DE',
    voiceFemale: 'Wavenet-C',
    voiceMale: 'Wavenet-D'
  },
  {
    code: 'di', // Dari
    speechCode: '',
    voiceFemale: '',
    voiceMale: ''
  },
  {
    code: 'en', // English
    speechCode: 'en-GB',
    voiceFemale: 'Neural2-C',
    voiceMale: 'Neural2-D'
  },
  {
    code: 'es', // Spanish
    speechCode: 'es-ES',
    voiceFemale: 'Neural2-E',
    voiceMale: 'Neural2-F'
  },
  {
    code: 'fa', // Farsi (Persian)
    speechCode: '',
    voiceFemale: '',
    voiceMale: ''
  },
  {
    code: 'fr', // French
    speechCode: 'fr-FR',
    voiceFemale: 'Neural2-C',
    voiceMale: 'Neural2-D'
  },
  {
    code: 'hr', // Croatian
    speechCode: '',
    voiceFemale: '',
    voiceMale: ''
  },
  {
    code: 'it', // Italien
    speechCode: 'it-IT',
    voiceFemale: 'Neural2-A',
    voiceMale: 'Neural2-C'
  },
  {
    code: 'kuk', // Kurmandschi (Kurdish)
    speechCode: '',
    voiceFemale: '',
    voiceMale: ''
  },
  {
    code: 'kus', // Sorani (Kurdish)
    speechCode: '',
    voiceFemale: '',
    voiceMale: ''
  },
  {
    code: 'ps', // Pashto
    speechCode: '',
    voiceFemale: '',
    voiceMale: ''
  },
  {
    code: 'pt', // Portuguese
    speechCode: 'pt-PT',
    voiceFemale: 'Wavenet-D',
    voiceMale: 'Wavenet-B'
  },
  {
    code: 'ru', // Russian
    speechCode: 'ru-RU',
    voiceFemale: 'Wavenet-C',
    voiceMale: 'Wavenet-D'
  },
  {
    code: 'sq', // Albanian
    speechCode: '',
    voiceFemale: '',
    voiceMale: ''
  },
  {
    code: 'sr', // Serbian
    speechCode: 'sr-RS',
    voiceFemale: 'Standard-A',
    voiceMale: '' // there is only a female voice available (16.04.2023)
  },
  {
    code: 'ta', // Tamil
    speechCode: 'ta-IN',
    voiceFemale: 'Wavenet-A',
    voiceMale: 'Wavenet-B'
  },
  {
    code: 'ti', // Tigrinja
    speechCode: '',
    voiceFemale: '',
    voiceMale: ''
  },
  {
    code: 'tr', // Turkish
    speechCode: 'tr-TR',
    voiceFemale: 'Wavenet-D',
    voiceMale: 'Wavenet-E'
  },
  {
    code: 'uk', // Ukrainian
    speechCode: 'uk-UA',
    voiceFemale: 'Wavenet-A',
    voiceMale: '' // there is only a female voice available (16.04.2023)
  }
];

// TODO: outsource this to our server
const apiKey = 'AIzaSyBufJGIhuLeE5N9UPN8PvMEMCu0Yytc1sY'; // Google Cloud Translate API Key, Developer Account Joshua Drewlow

/**
 * Returns true if text-to-speech is available for the given language.
 *
 * @param {string} languageCode - language code according to googleAPIlanguages.code
 * @returns true if text-to-speech is available for the given language code
 */
export function isGoogleTextToSpeechAvailable(langCode) {
  const langSetting = googleAPIlanguages.filter((language) => language.code == langCode);
  return langSetting.length > 0 && langSetting[0].speechCode != '';
}

/**
 * Returns true if different voice types are available for the given language.
 *
 * @param {string} langCode - language code according to googleAPIlanguages.code
 * @returns true if different voice types are available for the given language code
 */
export function areGoogleVoiceTypesAvailable(langCode) {
  const langSetting = googleAPIlanguages.filter((language) => language.code == langCode);

  if (!langSetting || langSetting.length == 0 || !langSetting[0].voiceFemale || !langSetting[0].voiceMale) {
    // language doesn't exist or one of the voice types doesn't exist
    return false;
  }

  return langSetting[0].voiceFemale != langSetting[0].voiceMale;
}

/**
 * Get text to speech data for given text and language.
 *
 * @param {string} text - text to translate
 * @param {string} languageCode - language code according to googleAPIlanguages.code
 * @param {string} voiceType - voice type code; expected values F or M; default F
 * @returns speech result as string
 */
export function requestTextToSpeech(requestText, languageCode, voiceType) {
  return new Promise((resolve, reject) => {
    const langSetting = googleAPIlanguages.filter((language) => language.code == languageCode);

    if (langSetting.length == 0 || langSetting[0].speechCode == '') {
      return reject(new Error('No text to speech available.'));
    }

    const langCode = langSetting[0].speechCode;
    let voice = '';
    if (
      (voiceType == 'M' && langSetting[0].voiceMale) ||
      (voiceType == 'F' && !langSetting[0].voiceFemale && langSetting[0].voiceMale)
    ) {
      // male voice selected or female not available
      voice = langSetting[0].voiceMale;
    } else if (
      (voiceType == 'F' && langSetting[0].voiceFemale) ||
      (voiceType == 'M' && !langSetting[0].voiceMale && langSetting[0].voiceFemale)
    ) {
      // female voice selected or male not available
      voice = langSetting[0].voiceFemale;
    } else {
      return reject(new Error('Requested voice type not available: ' + voiceType));
    }

    axios
      .post('https://texttospeech.googleapis.com/v1/text:synthesize?key=' + apiKey, {
        audioConfig: {
          audioEncoding: 'LINEAR16',
          pitch: 0,
          speakingRate: 1,
          effectsProfileId: ['handset-class-device']
        },
        input: {
          text: requestText
        },
        voice: {
          languageCode: langCode,
          name: langCode + '-' + voice
        }
      })
      .then((result) => {
        // handle the result
        if (result.status == 200 && result.data.audioContent && result.data.audioContent != '') {
          return resolve(result.data.audioContent);
        } else {
          return reject(result.status + ', ' + result.statusText);
        }
      })
      .catch(function (error) {
        if (error.message) {
          return reject(error.message);
        }
        return reject(error);
      });
  });
}

/**
 * Get text translation for a given text and language.
 *
 * @param {string} text - text to translate
 * @param {string} languageCode - language code according to googleAPIlanguages.code
 * @returns translation result as string
 */
export function requestTextTranslation(requestText, languageCode) {
  return new Promise((resolve, reject) => {
    if (!languageCode) {
      return reject(new Error('No target language'));
    }

    if (!requestText) {
      return reject(new Error('No text'));
    }

    axios
      .post('https://translation.googleapis.com/language/translate/v2?key=' + apiKey, {
        q: requestText,
        target: languageCode,
        format: 'text'
      })
      .then((result) => {
        if (
          result.status == 200 &&
          result.data.data.translations &&
          result.data.data.translations.length > 0 &&
          result.data.data.translations[0].translatedText &&
          result.data.data.translations[0].translatedText != ''
        ) {
          return resolve(result.data.data.translations[0].translatedText);
        } else {
          return reject(result.status + ', ' + result.statusText);
        }
      })
      .catch(function (error) {
        if (error.message) {
          return reject(error.message);
        }
        return reject(error);
      });
  });
}

const captchaKey = '6LdiTSslAAAAABoyCdJ5iBddLSNLDcQ5m8lxQgPo';
/**
 * Verifies captcha v3 and returns token to send to server
 * https://developers.google.com/recaptcha/docs/v3
 * @returns token for server communication
 */
export function verifyCaptcha() {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(captchaKey, {action: 'submit'})
        .then(function (token) {
          console.log('Captcha verified');
          resolve(token);
        })
        .catch((error) => {
          console.log('Error executing captcha verify:', error);
          reject(error);
        });
    });
  });
}


import {defineComponent} from 'vue';
import {emitter} from '@/main';
import {isTextToSpeechAvailable} from '../composables/languageHelper';

export default defineComponent({
  name: 'SpeakButton',
  props: {
    emitEvent: {type: String, required: true},
    currentPatientLanguage: {type: String, required: true},
    isAudioPlaying: {type: Boolean, required: true}
  },
  data: function () {
    return {
      isOnline: false // true is there is an internet connection
    };
  },
  mounted() {
    this.emitEvent;
    this.currentPatientLanguage;

    // Check for initial connection.
    this.checkForInternet();

    // Listen to on/offline events.
    window.addEventListener('offline', () => {
      this.isOnline = false;
    });
    window.addEventListener('online', () => {
      this.isOnline = true;
    });
  },
  methods: {
    /**
     * Checks if there is an internet connection.
     */
    checkForInternet: function () {
      if (navigator.onLine) {
        this.isOnline = true;
      } else {
        this.isOnline = false;
      }
    },
    /**
     * Returns true when text-to-speech is available for the current patient language.
     */
    isSpeechAvailable(): boolean {
      return isTextToSpeechAvailable(this.currentPatientLanguage);
    },
    /**
     * The user clicked on the loudspeaker, emit the event given as props.
     */
    speak() {
      emitter.emit(this.emitEvent);
    }
  }
});

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6799ceb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbarButton" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { id: "languageDropdown" }
const _hoisted_4 = { class: "firstRow" }
const _hoisted_5 = { class: "listElement" }
const _hoisted_6 = { class: "labelElement" }
const _hoisted_7 = { class: "nextRows" }
const _hoisted_8 = { class: "listElement" }
const _hoisted_9 = { class: "labelElement" }
const _hoisted_10 = {
  key: 0,
  class: "nextRows"
}
const _hoisted_11 = { class: "listElement" }
const _hoisted_12 = { class: "labelElement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleLanguageDropdown()))
      }, [
        _createElementVNode("img", {
          src: require('@/assets/images/language.png')
        }, null, 8, _hoisted_2)
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_close_button, { "close-method": _ctx.toggleLanguageDropdown }, null, 8, ["close-method"]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('language.appLanguage')) + ":", 1),
          _createVNode(_component_ion_select, {
            placeholder: _ctx.$t('common.choose'),
            interface: "popover",
            modelValue: _ctx.appLanguageModel,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.appLanguageModel) = $event)),
            onIonChange: _ctx.chooseAppLanguage,
            class: _normalizeClass({bigger: _ctx.currentAppLanguage})
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableAppLanguages(), (language, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                  true
                    ? (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: 0,
                        value: language.code
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(language.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _: 1
          }, 8, ["placeholder", "modelValue", "onIonChange", "class"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('language.patientLanguage')) + ":", 1),
            _createVNode(_component_ion_select, {
              placeholder: _ctx.$t('common.choose'),
              interface: "popover",
              mode: "md",
              modelValue: _ctx.patientLanguageModel,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.patientLanguageModel) = $event)),
              onIonChange: _ctx.choosePatientLanguage,
              class: _normalizeClass({bigger: _ctx.currentPatientLanguage})
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language, i) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                    true
                      ? (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: 0,
                          value: language.code
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(language.name + ' – ' + language[_ctx.currentAppLanguage]), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ]),
              _: 1
            }, 8, ["placeholder", "modelValue", "onIonChange", "class"])
          ])
        ])
      ]),
      (_ctx.areTypesAvailable())
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('language.voiceType')) + ":", 1),
              _createVNode(_component_ion_select, {
                placeholder: _ctx.$t('common.choose'),
                interface: "popover",
                modelValue: _ctx.voiceTypeModel,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.voiceTypeModel) = $event)),
                onIonChange: _ctx.chooseVoiceType,
                class: _normalizeClass({bigger: _ctx.currentVoiceType})
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.voiceTypes, (voiceType, i) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                      true
                        ? (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: 0,
                            value: voiceType.code
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, _toDisplayString(voiceType[_ctx.currentAppLanguage]), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]),
                _: 1
              }, 8, ["placeholder", "modelValue", "onIonChange", "class"])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.isDropdownShown]
    ])
  ], 64))
}
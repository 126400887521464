
import {defineComponent, ref} from 'vue';
import {verifyCaptcha} from '../composables/googleCloud.js';
import axios from 'axios';
import {ServerResponse} from '@/model/interfaces.js';
import {matomoTrackEvent, EVENT_FEEDBACK, FORM_SHOWN, FORM_CLOSED, FEEDBACK_SUBMITED} from '../composables/matomo.js';
import {emitter} from '@/main';
import CloseButton from './CloseButton.vue';

export default defineComponent({
  components: {CloseButton},
  name: 'FeebackForm',
  data() {
    return {
      isDropdownShown: false,
      enableSubmit: false,
      wordCountMinimum: 5, // amount of words have to be written in message to enable submit button
      messageValue: '',
      contactValue: '',
      formIsReadOnly: false, // true when form is read only (e.g. during submit while waiting for answer)
      showMessageSent: false, // true when message was sent
      showMessageError: false // true when error occured during message sending
      // TODO: show min words count error, show no internet error
    };
  },
  setup() {
    const textArea = ref(null);
    return {
      textArea
    };
  },
  mounted() {
    emitter.on('openedLanguageDropdown', () => {
      // close language dropdown if shown
      this.isDropdownShown = false;
    });
  },
  methods: {
    /**
     * Toggles visibilits of feedback dropdown
     */
    toggleFeedbackDropdown() {
      this.resetForm();
      this.isDropdownShown = !this.isDropdownShown;
      if (this.isDropdownShown) {
        emitter.emit('openedFeedbackDropdown');
        matomoTrackEvent(EVENT_FEEDBACK, FORM_SHOWN, '', '');
      } else {
        matomoTrackEvent(EVENT_FEEDBACK, FORM_CLOSED, '', '');
      }
    },
    /**
     * Count words in textarea
     */
    getMessageWordCount(): number {
      const words = this.messageValue.split(/[\n\r\s]+/);
      return words.length - 1;
    },
    /**
     * Handles textarea input.
     */
    handleTextAreaInput() {
      // check word count
      this.enableSubmit = this.getMessageWordCount() >= this.wordCountMinimum;

      // handle height
      const textarea = this.textArea as unknown as HTMLElement;
      if (textarea) {
        const numberOfLineBreaks = (this.messageValue.match(/\n/g) || []).length;

        if (numberOfLineBreaks >= 3) {
          // min-height + lines x line-height + padding + border
          const newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
          textarea.style.height = `${newHeight}px`;
        }
      }
    },
    /**
     * User clicked on submit
     */
    submitButtonAction() {
      this.showMessageError = false;
      this.enableSubmit = false;
      this.formIsReadOnly = true;
      verifyCaptcha()
        .then((token: string) => {
          matomoTrackEvent(EVENT_FEEDBACK, FEEDBACK_SUBMITED, '', '');
          this.submitFeedbackWithToken(token)
            .then((result: string) => {
              if (result == 'ok') {
                console.log('Message was sent');
                this.showMessageSent = true;
                setTimeout(() => this.closeAfterMessageSent(), 1500);
              } else {
                this.showError();
              }
            })
            .catch((error) => {
              this.showError();
              throw new Error('Error submitting Feedback: ' + JSON.stringify(error));
            });
        })
        .catch((error) => {
          this.showError();
          console.warn('An error occured: ', error);
        });
    },
    /** Show sent message and close dropdown */
    closeAfterMessageSent() {
      console.log('close feedback form');
      this.showMessageSent = false;
      this.isDropdownShown = false;
      this.resetForm();
    },
    /**
     * Show error
     */
    showError() {
      this.showMessageError = true;
      this.formIsReadOnly = false;
      this.enableSubmit = true;
    },
    /**
     * Submit feedback to send api with given captcha token
     * @param token token received from captcha API
     */
    submitFeedbackWithToken(token: string): Promise<string> {
      return new Promise((resolve, reject) => {
        if (window.location.href.includes('localhost')) {
          // don't post on localhost
          console.warn('Message sending on localhost deactivated: we just pretent to');
          return resolve('ok');
        }

        const values = {};
        values['g-recaptcha-response'] = token;
        values['message'] = this.messageValue;
        if (this.contactValue && this.contactValue != '') {
          values['contact'] = this.contactValue;
        }
        values['isFeedbackForm'] = 'ja bitte!';

        axios
          .post<ServerResponse>('https://sprechendebilder.ch/api/mail_send.php', {
            values
          })
          .then((response) => {
            const {data} = response;
            if (data.status && data.status == 'success' && data.message && data.message == 'message sent') {
              resolve('ok');
            } else {
              reject('not ok');
            }
          })
          .catch((error: Error) => {
            if (error && error.message) {
              return reject(error.message);
            }
            reject(JSON.stringify(error));
          });
      });
    },
    /**
     * Resets input data of form.
     */
    resetForm() {
      this.messageValue = '';
      this.contactValue = '';
      this.formIsReadOnly = false;
      this.showMessageError = false;
      this.showMessageSent = false;
    }
  },
  unmounted() {
    emitter.off('openedLanguageDropdown');
  }
});

import {createRouter, createWebHashHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/images/mostUsed'
  },
  {
    path: '/images/:categoryId',
    component: () => import('../views/ImagesView.vue')
  },
  {
    path: '/search/',
    component: () => import('../views/ImagesView.vue')
  },
  {
    path: '/images/:categoryId/:imageKey',
    component: () => import('../views/ImagesView.vue')
  },
  {
    path: '/import/',
    component: () => import('../views/ExcelImport.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/images/mostUsed'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    // always scroll to top, source: https://router.vuejs.org/guide/advanced/scroll-behavior.html
    //return { left: 0, top: 0 }; (standard, but doesn't work)
    const top = document.getElementById('viewTitle');
    const googleTranslateContainer = document.getElementById('googleTranslateContainer');
    if (googleTranslateContainer) {
      googleTranslateContainer.scrollIntoView();
      return;
    }
    if (top) {
      top.scrollIntoView();
    }
  },
  routes
});

export default router;

/**
 * Represents an image category
 */
export interface SpeakingCategory {
  id: number;
  name: TranslationLanguage; // translation of category name
  icon: string; // file name for icon
  path: string; // url path for this category
}

/**
 * Represents one image shown in UI.
 */
export interface SpeakingImage {
  id: number;
  imageKey: string; // file name of image
  name: TranslationLanguage; // translation of image name
  categoryId: number; // for now can only belong to one category
  keywordsIds: number[]; // references id's of ImageKeyword
  linkedImagesIds: number[]; // references id's of SpeakingImages
  extension: string; // possible extension to show under detail view of image
}

/**
 * Represents statistic of one SpeakinImage.
 */
export interface ImageUsage {
  imageId: number; // id of image
  lastUpdate: number; // timestamp in ms of last update of image, used to precicesly filter most used images
  clickedCount: number; // counts clicks from list (inclusive on start page) and from search, doesn't count returning from other DetailView
}

/**
 * Represents a keyword, a searchable word attached to an image.
 */
export interface ImageKeyword {
  id: number;
  name: TranslationLanguage;
}

/**
 * Represents translations of an image, category or a keyword.
 * Can be expanded with other languages.
 */
export interface TranslationLanguage {
  de: TranslationObject;
  ar?: TranslationObject;
  di?: TranslationObject;
  en?: TranslationObject;
  es?: TranslationObject;
  fa?: TranslationObject;
  fr?: TranslationObject;
  hr?: TranslationObject;
  it?: TranslationObject;
  kuk?: TranslationObject;
  kus?: TranslationObject;
  pt?: TranslationObject;
  ps?: TranslationObject;
  ru?: TranslationObject;
  sq?: TranslationObject;
  sr?: TranslationObject;
  uk?: TranslationObject;
  ta?: TranslationObject;
  ti?: TranslationObject;
  tr?: TranslationObject;
}

/**
 * Represents text and text to speech translation image, category or a keyword.
 * Can be expanded with other languages.
 */
export interface TranslationObject {
  text: string;
  textToSpeech?: string;
}

/**
 * Represents a prototype login
 */
export interface LoginType {
  password: string;
}

/**
 * Represents supported languages for the app (both app and patient language):
 * {string} name - native name of the language, e.g. "عربي" for Arabic
 * {string} code - ISO-639-1 code of the language, also used to persist name and set locale
 * {boolean} showAsAppLanguage - true when this language appears as app language, false when only patient language
 */
export interface SupportedLanguage {
  name: string;
  code: string;
  showAsAppLanguage?: boolean;
}

/**
 * Represents supported voice types:
 * {string} code - F = female, M = male
 */
export interface SupportedVoiceTypes {
  code: string;
}

/**
 * Represents a language supported from Google Cloud API
 */
export interface GoogleCloudLanguage {
  code: string;
  speechCode: string;
  voice: string;
}

/**
 * Represents one entry from the excel file used for data import
 */
export interface ExcelEntry {
  imageKey: string;
  category: string;
  extension?: string;
  disabled?: number;
  // image names i18n
  imageName_ar: string; // Arabic
  imageName_de: string; // German
  imageName_di: string; // Dari
  imageName_en: string; // English
  imageName_es: string; // Spanish
  imageName_fa: string; // Farsi (Persian)
  imageName_fr: string; // French
  imageName_hr: string; // Croatian
  imageName_it: string; // Italien
  imageName_kuk: string; // Kurmandschi (Kurdish)
  imageName_kus: string; // Sorani (Kurdish)
  imageName_ps: string; // Pashto
  imageName_pt: string; // Portuguese
  imageName_ru: string; // Russian
  imageName_sq: string; // Albanian
  imageName_sr: string; // Serbian
  imageName_ta: string; // Tamil
  imageName_ti: string; // Tigrinja
  imageName_tr: string; // Turkish
  imageName_uk: string; // Ukrainian

  // keywords i18n (only for app languages, not for patient languages!)
  keywords_ar: string; // Arabic
  keywords_de: string; // German
  keywords_en: string; // English
  keywords_es: string; // Spanish
  keywords_fr: string; // French
  keywords_it: string; // Italien
  keywords_uk: string; // Ukrainian
}

/**
 * Represents a voice type of text to speech API from googleCloud.js
 */
export enum VoiceTypes {
  MALE = 'M',
  FEMALE = 'F'
}

/**
 * Represents a local storage item
 */
export enum StorageItems {
  IS_USER_LOGGED_IN = 'isUserLoggedIn',
  MATOMO_ID = 'matomoId',
  APP_LANGUAGE = 'appLanguage',
  PATIENTLANGUAGE = 'patientLanguage',
  VOICE_TYPE = 'voiceType',
  IMAGE_USAGE_LIST = 'imageUsageList'
}

/**
 * Interfaces just to make eslint happy.
 */

export interface ProcessEnv {
  NODE_ENV: string;
  BASE_URL: string;
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

// Server response from mail send
export interface ServerResponse {
  status: string;
  message: string;
}

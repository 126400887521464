import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18639403"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isOnline && _ctx.isSpeechAvailable())
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "speakButton",
        class: "loudspeaker",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.speak()))
      }, [
        (!_ctx.isAudioPlaying)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "loudspeaker",
              src: require('@/assets/images/loudspeaker.png')
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.isAudioPlaying)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "loudspeaker",
              src: require('@/assets/images/loudspeaker.gif')
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
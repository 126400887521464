
import {toastController} from '@ionic/vue';
import {defineComponent} from 'vue';
import UpdateMixin from '../mixins/appUpdateMixin.js';
import {emitter} from '../main';

export default defineComponent({
  name: 'UpdatePopup',
  mixins: [UpdateMixin],
  mounted() {
    emitter.on('updatePWA', (event) => this.presentToast(event));
  },
  unmounted() {
    emitter.off('updatePWA');
  },
  methods: {
    async presentToast(event) {
      const toast = await toastController.create({
        message: 'Eine neue Version ist verfügbar.',
        duration: 0,
        buttons: [
          {
            text: 'Aktualisieren',
            role: 'update',
            handler: () => {
              UpdateMixin.methods.refreshApp(event);
            }
          },
          {
            text: 'Abbrechen',
            role: 'cancel',
            handler: () => {
              // do nothing only close
            }
          }
        ]
      });

      await toast.present();

      const {role} = await toast.onDidDismiss();
      if (role) {
        console.log(`Dismissed with role: ${role}`);
      }
    }
  }
});


import {defineComponent} from 'vue';

export default defineComponent({
  name: 'CloseButton',
  props: {
    /**
     * Method to execute when button clicked.
     */
    closeMethod: {type: Function, required: true}
  },
  methods: {
    /**
     * Execute close method from props.
     */
    closeButtonClicked() {
      this.closeMethod();
    }
  }
});

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5686ec4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "close-button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeButtonClicked()))
  }, [
    _createElementVNode("img", {
      src: require('@/assets/images/close_white.png')
    }, null, 8, _hoisted_1)
  ]))
}

import {IonIcon} from '@ionic/vue';
import {defineComponent} from 'vue';
import {closeOutline} from 'ionicons/icons';
import {isTablet} from 'mobile-device-detect';

export default defineComponent({
  name: 'InstallPopupIos',
  components: {
    IonIcon
  },
  setup() {
    return {
      closeOutline
    };
  },
  data: () => ({
    showPopup: true
  }),
  methods: {
    handleScroll(): void {
      this.showPopup = false;
    },
    dismiss(): void {
      this.showPopup = false;
    },
    isRunningOnTablet(): boolean {
      return isTablet;
    }
  }
});

export default {
  methods: {
    /**
     * Returns true if running on mobile platform.
     * @returns true when mobile platform
     */
    isMobileMixin() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    }
  }
};


import {IonTextarea, IonText} from '@ionic/vue';
import {defineComponent} from 'vue';
import {requestTextToSpeech, requestTextTranslation} from '../composables/googleCloud.js';
import {emitter} from '@/main';
import {matomoTrackEvent, EVENT_TRANSLATE, TEXT_TO_TRANSLATE, READ_ALOUD} from '../composables/matomo.js';
import {getCurrentVoiceType, isTextToSpeechAvailable} from '../composables/languageHelper';
import SpeakButton from './SpeakButton.vue';

export default defineComponent({
  name: 'TranslateView',
  components: {
    IonTextarea,
    SpeakButton,
    IonText
  },
  data: function () {
    return {
      translationTextRequested: '', // know which text we asked for text translation
      languageRequested: '', // know which text language we asked for text
      translationResult: '', // contains translated text
      targetLanguage: '', // desired language for text translation
      speechRequested: '', // to know which text we sent to speech api to only ask once if same translation
      speechResult: '', // raw audio data of speech received from google cloud api
      speechFile: '', // data container for browser to play
      errorMessage: '', // message to show in browser
      isAudioPlaying: false, // true when audio is playing of text to speech
      currentPatientLanguage: '', // patient language that has been selected in the language selector
      speakEvent: 'onSpeakTranslation', // speaking emitter event
      isLoading: false, // Indicates if the translation is loading or not
      debouncedInput: '', //This is only used for the tracking of the debounced computed property translationText and should not be used otherwise
      timeout: -1 //Current debounce timer. Only used for the debounced computed property translationText and should not be used otherwise
    };
  },
  mounted() {
    // Load selected patient language.
    this.loadPatientLanguage();

    // Listen to emitter events.
    emitter.on('patientLanguageChanged', (patientLanguage: string) => this.patientLanguageChanged(patientLanguage));
    emitter.on(this.speakEvent, () => this.speak());

    // Listen to audio ended event.
    (this.$refs.translationAudio as HTMLAudioElement).addEventListener('ended', () => this.audioEndedEvent());
  },
  unmounted() {
    // Don't listen to emitter events anymore.
    // Important: do NOT stop listening to patientLanguageChanged, since this ALSO STOPS the listening in the component ViewTitle present in the same page!!!
    //emitter.off('patientLanguageChanged');
    emitter.off(this.speakEvent);

    // Don't listen to audio ended event anymore.
    if (this.$refs.translationAudio) {
      (this.$refs.translationAudio as HTMLAudioElement).removeEventListener('ended', () => this.audioEndedEvent());
    }
  },
  computed: {
    /**
     * Debounced computed property for the translation text. Only updates after 750 milliseconds of no input. After the timeout it
     * triggers a translation and updates the values.
     */
    translationText: {
      get() {
        return this.debouncedInput;
      },
      set(val: string) {
        if (this.timeout || this.timeout != -1) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
          if (this.debouncedInput.length > 0) this.translate();
        }, 750);
      }
    }
  },
  methods: {
    /**
     * Loads patient language from storage.
     */
    loadPatientLanguage() {
      if (localStorage.getItem('patientLanguage')) {
        const lang = localStorage.getItem('patientLanguage') || '';
        if (lang != null && lang != '') {
          this.currentPatientLanguage = lang;
          this.targetLanguage = this.currentPatientLanguage;
        } else {
          console.log("Failed to load patient language. Setting to ''");
          localStorage.setItem('patientLanguage', '');
        }
      }
    },
    /**
     * Another patient language has been selected
     */
    patientLanguageChanged(newPatientLanguage: string) {
      this.currentPatientLanguage = newPatientLanguage;
      this.targetLanguage = this.currentPatientLanguage;

      if (this.translationText.length > 0) {
        this.translate();
      }
    },
    /**
     * Return list of SpeakingImages according to given imageIds.
     */
    translate() {
      this.isLoading = true;
      this.errorMessage = '';

      matomoTrackEvent(
        EVENT_TRANSLATE,
        TEXT_TO_TRANSLATE,
        '(' + this.$i18n.locale + ' → ' + this.targetLanguage + ') ' + this.translationText,
        ''
      );

      if (
        this.translationText != '' &&
        this.translationTextRequested != '' &&
        this.translationText == this.translationTextRequested &&
        this.targetLanguage != '' &&
        this.languageRequested != '' &&
        this.targetLanguage == this.languageRequested
      ) {
        console.log('We already asked for this translation.');
        this.isLoading = false;
        return;
      }

      requestTextTranslation(this.translationText, this.targetLanguage)
        .then((result: string) => {
          this.translationTextRequested = this.translationText;
          this.languageRequested = this.targetLanguage;
          console.log(`Translated text: ${result}`);
          this.translationResult = result;
          this.isLoading = false;
        })
        .catch((error: Error) => {
          this.isLoading = false;
          console.warn(error);
          if (error && error.message && error.message == 'No target language') {
            this.setErrorMessage(this.$t('translation.pleaseSelectLanguage'));
          } else {
            console.log('Error:', error);
            this.setErrorMessage(this.$t('translation.error') + ': ' + JSON.stringify(error));
          }
        });
    },
    /**
     * User clicked on text to speech button.
     */
    speak() {
      this.errorMessage = '';

      matomoTrackEvent(EVENT_TRANSLATE, READ_ALOUD, '(' + this.targetLanguage + ') ' + this.translationResult, '');

      if (
        this.speechRequested == this.translationResult &&
        this.speechResult != '' &&
        this.translationText == this.translationTextRequested
      ) {
        console.log('we already have recevied speech data for this translation');
        this.playOutput(this.speechResult);
      } else {
        const promiseWait = new Array<Promise<void>>();
        if (this.translationText != this.translationTextRequested) {
          // we have a new translation text and should request first
          console.log('We need to get new text translation first.');

          const promisedTextTranslation = requestTextTranslation(this.translationText, this.targetLanguage)
            .then((result: string) => {
              this.translationTextRequested = this.translationText;
              this.languageRequested = this.targetLanguage;
              console.log('Translated text: ' + result);
              this.translationResult = result;
            })
            .catch((error: Error) => {
              if (error && error.message && error.message == 'No target language') {
                this.setErrorMessage(this.$t('translation.pleaseSelectLanguage'));
              } else {
                console.log('Error:', error);
                this.setErrorMessage(this.$t('translation.error') + ': ' + JSON.stringify(error));
              }
            });
          promiseWait.push(promisedTextTranslation);
        }

        Promise.all(promiseWait)
          .then(() => requestTextToSpeech(this.translationResult, this.targetLanguage, getCurrentVoiceType()))
          .then((speechData: string) => {
            this.speechResult = speechData;
            this.speechRequested = this.translationResult;
            this.playOutput(speechData);
          })
          .catch((error: Error) => {
            console.log('Error:', error);
            if (error && error.message) {
              this.setErrorMessage(this.$t('translation.error') + ': ' + error.message);
            } else if (error) {
              console.log('Error:', error);
              this.setErrorMessage(this.$t('translation.error') + ': ' + JSON.stringify(error));
            }
          });
      }
    },
    /**
     * Returns true when text-to-speech is available for the target language.
     */
    isSpeechAvailable(): boolean {
      return isTextToSpeechAvailable(this.targetLanguage);
    },
    /**
     * Play audio from LINEAR16 encoding
     *
     * Source: https://cloud.google.com/text-to-speech/docs/reference/rest/v1/text/synthesize
     * Source: https://stackoverflow.com/questions/12951438/play-audio-using-base64-data-in-html5
     * Source: https://codepen.io/CSWApps/pen/PJevMN
     */
    playOutput(data: string) {
      this.speechFile = 'data:audio/wav;base64,' + data;
      (this.$refs.translationAudio as HTMLAudioElement).src = this.speechFile;
      (this.$refs.translationAudio as HTMLAudioElement).play().catch((error) => {
        console.error('Error playing audio:', error);
      });
      this.isAudioPlaying = true;
    },
    /**
     * Called after audio is stoped.
     */
    audioEndedEvent() {
      console.log('Stopped');
      this.isAudioPlaying = false;
    },
    /**
     * Set en error message and clear previous translation.
     */
    setErrorMessage(message: string) {
      this.resetTranslation();
      this.errorMessage = message;
    },
    /**
     * Reset translation settings.
     */
    resetTranslation() {
      this.translationText = '';
      this.translationResult = '';
    }
  }
});


import {IonSelect, IonSelectOption} from '@ionic/vue';
import {SupportedLanguage, SupportedVoiceTypes} from '@/model/interfaces';
import languageData from '@/assets/languageData.json';
import {defineComponent} from 'vue';
import {emitter} from '@/main';
import {
  getCurrentVoiceType,
  setCurrentVoiceType,
  isLanguageAvailable,
  areVoiceTypesAvailable
} from '../composables/languageHelper';
import {
  matomoTrackEvent,
  EVENT_LANGUAGE,
  SELECTOR_SHOWN,
  SELECTOR_CLOSED,
  APP_LANGUAGE_SELECTED,
  PATIENT_LANGUAGE_SELECTED,
  VOICE_TYPE_SELECTED
} from '../composables/matomo.js';
import CloseButton from './CloseButton.vue';

export default defineComponent({
  name: 'LanguageSelector',
  components: {
    IonSelect,
    IonSelectOption,
    CloseButton
  },
  data() {
    return {
      languages: languageData.languages as Array<SupportedLanguage>,
      voiceTypes: languageData.voiceTypes as Array<SupportedVoiceTypes>,
      currentAppLanguage: '',
      appLanguageModel: '',
      currentPatientLanguage: '',
      patientLanguageModel: '',
      currentVoiceType: '',
      voiceTypeModel: '',
      isDropdownShown: false
    };
  },
  created() {
    this.loadLanguages();
  },
  mounted() {
    emitter.on('openedFeedbackDropdown', () => {
      // close feedback dropdown if shown
      this.isDropdownShown = false;
    });
  },
  methods: {
    /**
     * Return available app languages.
     */
    availableAppLanguages() {
      return this.languages.filter((language) => language.showAsAppLanguage);
    },
    /**
     * Change app language and store it in local and cookie.
     */
    chooseAppLanguage() {
      this.toggleLanguageDropdown();
      this.currentAppLanguage = this.appLanguageModel;
      this.$i18n.locale = this.currentAppLanguage;
      localStorage.setItem('appLanguage', this.currentAppLanguage);
      matomoTrackEvent(EVENT_LANGUAGE, APP_LANGUAGE_SELECTED, this.currentAppLanguage, '');
      emitter.emit('appLanguageChanged', this.currentAppLanguage);
    },
    /**
     * Change patient language and store it in cookie.
     */
    choosePatientLanguage() {
      this.toggleLanguageDropdown();
      this.currentPatientLanguage = this.patientLanguageModel;
      localStorage.setItem('patientLanguage', this.currentPatientLanguage);
      matomoTrackEvent(EVENT_LANGUAGE, PATIENT_LANGUAGE_SELECTED, this.currentPatientLanguage, '');
      emitter.emit('patientLanguageChanged', this.currentPatientLanguage);
    },
    /**
     * Change voice type and store it in cookie.
     */
    chooseVoiceType() {
      this.currentVoiceType = this.voiceTypeModel;
      setCurrentVoiceType(this.currentVoiceType);
      matomoTrackEvent(EVENT_LANGUAGE, VOICE_TYPE_SELECTED, this.currentVoiceType, '');
    },
    /**
     * Toggles visibility of language dropdown.
     */
    toggleLanguageDropdown() {
      this.isDropdownShown = !this.isDropdownShown;
      if (this.isDropdownShown) {
        emitter.emit('openedLanguageDropdown');

        matomoTrackEvent(
          EVENT_LANGUAGE,
          SELECTOR_SHOWN,
          this.currentAppLanguage + '-' + this.currentPatientLanguage,
          ''
        );
      } else {
        matomoTrackEvent(EVENT_LANGUAGE, SELECTOR_CLOSED, '', '');
      }
    },
    /**
     * Loads app language from locale and patient language from cookie (local storage).
     */
    loadLanguages() {
      // sort patients' languages according to their native name
      this.languages.sort((a, b) => {
        if (a.name < b.name) return -1;
        else return 1;
      });

      // load app language from locale
      this.currentAppLanguage = this.$i18n.locale;
      this.appLanguageModel = this.currentAppLanguage;

      // load patient language from cookie
      if (localStorage.getItem('patientLanguage')) {
        const patientLangCode = localStorage.getItem('patientLanguage') || '';
        if (patientLangCode != null && patientLangCode != '' && isLanguageAvailable(patientLangCode)) {
          this.currentPatientLanguage = patientLangCode;
          this.patientLanguageModel = this.currentPatientLanguage;
        } else {
          console.log("Failed to load patient language. Setting to ''");
          localStorage.setItem('patientLanguage', '');
        }
      } else {
        // reset for any case
        this.currentPatientLanguage = '';
        this.patientLanguageModel = '';
      }

      // load voice type from cookie
      this.currentVoiceType = getCurrentVoiceType();
      this.voiceTypeModel = this.currentVoiceType;
    },
    /**
     * Returns true when different voice types may be choosed for the current patient language.
     */
    areTypesAvailable(): boolean {
      return areVoiceTypesAvailable(this.currentPatientLanguage);
    }
  },
  unmounted() {
    emitter.off('openedFeedbackDropdown');
  }
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc7e33b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "container",
  id: "googleTranslateContainer"
}
const _hoisted_2 = {
  key: 0,
  class: "error"
}
const _hoisted_3 = { class: "input-area" }
const _hoisted_4 = { class: "translation-area" }
const _hoisted_5 = { class: "translation-output" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { ref: "translationAudio" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_speak_button = _resolveComponent("speak-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('translation.title')), 1),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ion_textarea, {
        id: "translation-input-field",
        placeholder: _ctx.$t('translation.textareaEnter') + ' (' + _ctx.$t('translation.textareaHint') + ')',
        modelValue: _ctx.translationText,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.translationText) = $event)),
        rows: 2,
        "auto-grow": true,
        wrap: "soft",
        onIonInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.translationResult = ''))
      }, null, 8, ["placeholder", "modelValue"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.translationResult)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _createVNode(_component_ion_text, { color: "medium" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('translation.title')), 1)
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.translationResult), 1)),
          (_ctx.translationResult != '')
            ? (_openBlock(), _createBlock(_component_speak_button, {
                key: 2,
                class: "speak-button",
                "emit-event": _ctx.speakEvent,
                "current-patient-language": _ctx.currentPatientLanguage,
                "is-audio-playing": _ctx.isAudioPlaying
              }, null, 8, ["emit-event", "current-patient-language", "is-audio-playing"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("audio", _hoisted_8, null, 512)
  ]))
}
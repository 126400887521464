/**
 * Language composable.
 * Handles functions to manage app and patient language.
 */

import {VoiceTypes} from '@/model/interfaces';
import languageData from '../assets/languageData.json';
import {isGoogleTextToSpeechAvailable, areGoogleVoiceTypesAvailable} from './googleCloud.js';

/**
 * Get the selected voice type from the cookie (localStorage).
 * Type is either F or M; default is F.
 */
export function getCurrentVoiceType() {
  let type = localStorage.getItem('voiceType');
  if (type != VoiceTypes.FEMALE && type != VoiceTypes.MALE) {
    type = VoiceTypes.FEMALE;
  }
  return type;
}

/**
 * Store the selected voice type in the cookie (localStorage).
 * @param {string} type - voice type; should be F or M; defaults to F
 */
export function setCurrentVoiceType(type: string) {
  if (type != VoiceTypes.FEMALE && type != VoiceTypes.MALE) {
    type = VoiceTypes.FEMALE;
  }
  localStorage.setItem('voiceType', type);
}

/**
 * Returns true if the given language is available.
 * @param {string} langCode - language code to check
 * @returns true if the given language is available
 */
export function isLanguageAvailable(langCode: string): boolean {
  return languageData.languages.filter((lang) => lang.code == langCode).length > 0;
}

/**
 * Returns true if text-to-speech is available for the given language.
 * @param {string} langCode - language code to check
 * @returns true if text-to-speech is available for the given language code
 */
export function isTextToSpeechAvailable(langCode: string): boolean {
  // for the moment we only support Google Cloud services
  return isGoogleTextToSpeechAvailable(langCode);
}

/**
 * Returns true if different voice types are available for the given language.
 * @param {string} langCode - language code to check
 * @returns true if different voice types are available for the given language
 */
export function areVoiceTypesAvailable(langCode: string): boolean {
  // for the moment we only support Google Cloud services
  return areGoogleVoiceTypesAvailable(langCode);
}


import {IonPage, IonApp, IonContent, IonItem, IonHeader, IonLabel, IonList, IonSplitPane, IonMenu} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import imageData from '@/assets/imageData.json';
import {emitter} from './main';
import mobileDetectMixin from './mixins/mobileDetectMixin.js';
import router from './router';
import MyToolbar from './components/MyToolbar.vue';
import packageJson from '../package.json';
import LoginComponent from './components/LoginComponent.vue';
import InstallPopupIos from './components/InstallPopupIos.vue';
import UpdatePopup from './components/UpdatePopup.vue';
import {isIOS, isSafari} from 'mobile-device-detect';
import {SpeakingCategory, StorageItems, TranslationObject} from './model/interfaces';
import {isLanguageAvailable} from './composables/languageHelper';
import GoogleTranslate from '@/components/GoogleTranslate.vue';
import CloseButton from '@/components/CloseButton.vue';

/**
 * Limit of px when to hide sidemenu & show tab menu.
 */
export const WINDOW_WIDTH_SIDEMENU_LIMIT = 750;

/**
 * True when password protection should be used.
 */
const USE_PASSWORD_PROTECTION = true;

export default defineComponent({
  name: 'App',
  components: {
    IonPage,
    IonApp,
    IonContent,
    IonItem,
    IonHeader,
    IonLabel,
    IonList,
    MyToolbar,
    LoginComponent,
    InstallPopupIos,
    UpdatePopup,
    IonSplitPane,
    IonMenu,
    GoogleTranslate,
    CloseButton
  },
  mixins: [mobileDetectMixin],
  data: function () {
    return {
      isMobile: false, // true when on mobile platform or window.innerWidth smaller than breaking point
      showCategoriesMobile: false, // true when categories in mobile menu are shown
      isCategoryShown: false, // true when image category view is shown (and also detail image view)
      appVersion: '', // current version of the app
      toggleAppVersion: false, // true when app version is shown
      isLoggedIn: false, // true when user entered once the password
      appLanguage: 'de', // language meant for healthcare worker, less dominant but for whole app
      patientLanguage: '', // language meant for patient, should be more present
      matomoId: '', // ID transmitted to Matomo that has been entered on login
      translationVisible: false // indicates if the translation panel is visible or not
    };
  },
  /**
   * Is called at initialisation of component.
   */
  setup() {
    const selectedIndex = ref(0);
    // console.log("content imageData.json", imageData);

    // load categories from data
    // TODO this should be done only once and than saved to WebStorage
    let categories = new Array<SpeakingCategory>();
    categories = imageData.categories;

    // added most used category manually because it's not mentioned in images data
    // Note: has to be excluded in rendering the menu
    // TODO: not necessary anymore weil es drin ist?
    // categories.push(
    //   imageData.categories.filter((category) => category.name == "mostUsed")[0]
    // );

    // TODO sort categories according to order im imageData.json

    return {
      categories,
      selectedIndex // index of currently selected category selected in ui
    };
  },
  watch: {
    /**
     * Watch route param path to recognize if we are in a category image view.
     */
    '$route.path': {
      handler: function (path: string) {
        if (path != '' && path.includes('images') && !path.includes('mostUsed')) {
          this.isCategoryShown = true;
        } else {
          this.isCategoryShown = false;
        }
      },
      deep: true,
      immediate: true
    },
    /**
     * Watch route param id to recognize when category changed.
     */
    '$route.params.categoryId': {
      handler: function (categoryPath: string) {
        if (categoryPath) {
          this.selectedIndex = this.categories.findIndex(
            (category) => category.path.toLowerCase() === categoryPath.toLowerCase()
          );
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    translate(category: SpeakingCategory): string {
      return (category.name[this.$i18n.locale] as TranslationObject).text;
    },
    /**
     * Handle category click.
     */
    categoryClicked(clickedIndex: number, category: SpeakingCategory) {
      this.selectedIndex = clickedIndex;

      // reset if mobile category menu is still open
      this.showCategoriesMobile = false;

      // notify Images component because we usually render images when route changes
      // special case: user is using search and clicks on same category
      emitter.emit('onCategoryClicked', category);

      emitter.emit('clearSearch');
    },
    /**
     * Resets the currently selected category to no selection.
     */
    resetCurrentCategoryUI() {
      this.selectedIndex = -1;
    },
    /**
     * User clicked to return home.
     * Resets category selection and cancel search if needed.
     */
    homeClicked() {
      emitter.emit('onHomeClicked');
      emitter.emit('clearSearch');

      // hot fix because for some reason onHomeClicked event in this vue is not executed when on other view
      this.showCategoriesMobile = false;
      this.resetCurrentCategoryUI();
    },
    /**
     * Toggles category menu of mobile view.
     */
    toggleCategoryMobileMenu() {
      this.showCategoriesMobile = !this.showCategoriesMobile;
    },
    /**
     * Set side menu to disabled if is smaller than limit.
     */
    onResizeEventHandler(e: UIEvent) {
      const w = e.target as Window;
      if (w.innerWidth && w.innerWidth < WINDOW_WIDTH_SIDEMENU_LIMIT) {
        console.log('Switch to mobile view.');
        this.isMobile = true;
      } else {
        console.log('Switch to desktop view.');
        this.isMobile = false;
      }
    },
    /**
     * Reloads current page.
     */
    reloadPage() {
      console.log('Reload page');
      router.go(0);
    },
    loadLoginState() {
      if (localStorage.getItem(StorageItems.IS_USER_LOGGED_IN)) {
        // read data from local storage
        try {
          const login = JSON.parse(
            localStorage.getItem(StorageItems.IS_USER_LOGGED_IN) || 'false' // we already check for null before
          ) as boolean;
          this.isLoggedIn = login;
        } catch (e) {
          console.warn('Failed to load login state.');
          localStorage.removeItem(StorageItems.IS_USER_LOGGED_IN);
          this.isLoggedIn = false;
        }
      } else {
        console.log('Not logged in');
        this.isLoggedIn = false;
      }
    },
    /**
     * Returns true when browser is Safari on iOS.
     */
    checkForIOS(): boolean {
      return isIOS && isSafari;
    },
    /**
     * Returns true when browser is running in standalone mode.
     */
    checkAddedToHomeScreen(): boolean {
      if ('standalone' in window.navigator && window.navigator['standalone']) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Loads app language from storage
     */
    loadAppLanguage() {
      if (localStorage.getItem('appLanguage')) {
        const lang = localStorage.getItem('appLanguage') || '';
        if (lang != null && lang != '' && isLanguageAvailable(lang)) {
          // console.log("Loaded language:" + lang);
          this.$i18n.locale = lang;
        } else {
          console.log('Failed to load language. Setting default: de');
          this.$i18n.locale = 'de';
          localStorage.setItem('appLanguage', 'de');
        }
      }
      // default locale is set in main.ts
    }
  },
  created() {
    this.loadAppLanguage();

    if (USE_PASSWORD_PROTECTION) {
      this.loadLoginState();
    } else {
      this.isLoggedIn = true;
    }

    this.appVersion = packageJson.version;
    console.log('App created, version:', this.appVersion);
    if (localStorage.getItem(StorageItems.MATOMO_ID) != null) {
      this.matomoId = localStorage.getItem(StorageItems.MATOMO_ID) || '';
    }
    window.addEventListener('resize', this.onResizeEventHandler.bind(window));
    window.addEventListener('orientationchange', this.reloadPage.bind(window), false);

    // detect if we should show mobile view (also shown on desktop if too small)
    if (mobileDetectMixin.methods.isMobileMixin() && window.innerWidth < WINDOW_WIDTH_SIDEMENU_LIMIT) {
      // mobile and width is smaller than limit
      console.log('Switch to mobile view.');
      this.isMobile = true;
    } else if (window.innerWidth < WINDOW_WIDTH_SIDEMENU_LIMIT) {
      // desktop and width is smaller than limit
      console.log('Switch to mobile view.');
      this.isMobile = true;
    } else {
      // desktop or mobile width is bigger than limit
      console.log('Switch to desktop view.');
      this.isMobile = false;
    }
  },
  mounted() {
    console.log('App mounted');

    emitter.on('onLoginSuccess', () => this.loadLoginState());

    emitter.on('showSearchResults', () => {
      // remove selected category when search result is shown
      this.resetCurrentCategoryUI();
    });

    emitter.on('onSearchFocused', () => {
      // reset categories of mobile if this should be the case
      this.showCategoriesMobile = false;
    });

    emitter.on('onImageClicked', () => {
      // user clicked on image in Images.vue
      this.showCategoriesMobile = false;
    });

    emitter.on('onLinkedImageClickedVoid', () => {
      // user clicked on image in ImageDetail.vue
      this.showCategoriesMobile = false;
    });

    emitter.on('onBackButton', () => {
      // user clicked on back in Images.vue
      this.showCategoriesMobile = false;
    });

    emitter.on('onHomeClicked', () => {
      // user clicked on home
      this.showCategoriesMobile = false;
      this.resetCurrentCategoryUI();
    });

    /**
     * User clicked on the translation button. Toggles the visibility for the GoogleTranslate component
     */
    emitter.on('translationVisible', () => {
      this.translationVisible = !this.translationVisible;
    });
  },
  unmounted() {
    // unsubscribe from events
    emitter.off('onLoginSuccess');
    emitter.off('onSearchFocused');
    emitter.off('onBackButton');
    emitter.off('onImageClicked');
    emitter.off('onLinkedImageClickedVoid');
    emitter.off('onSearch');
    emitter.off('onHomeClicked');
    emitter.off('translationVisible');
    window.removeEventListener('resize', this.onResizeEventHandler.bind(window));
    window.removeEventListener('orientationchange', this.reloadPage.bind(window));
  }
});

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cf0eaa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "toolbarButton",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backButtonClicked()))
      }, [
        _createElementVNode("img", {
          src: require('@/assets/images/back.png')
        }, null, 8, _hoisted_1)
      ]))
    : _createCommentVNode("", true)
}

import {IonIcon, IonItem, IonList} from '@ionic/vue';
import {searchOutline, closeCircleOutline, arrowBackOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {emitter} from '@/main';
import imageData from '@/assets/imageData.json';
import {ImageKeyword, TranslationObject} from '@/model/interfaces';
import LanguageSelector from '@/components/LanguageSelector.vue';
import FeedbackForm from '@/components/FeedbackForm.vue';
import BackButton from './BackButton.vue';

// minimum letters in search to show autocomplete list because it could be too long to "compile"
const AUTOCOMPLETE_SEARCH_MINIMUM = 3;

export default defineComponent({
  name: 'MyToolbar',
  components: {
    IonIcon,
    IonItem,
    IonList,
    LanguageSelector,
    FeedbackForm,
    BackButton
  },
  props: {
    isMobile: Boolean,
    translationVisible: Boolean
  },
  setup() {
    return {
      searchOutline,
      closeCircleOutline,
      arrowBackOutline
    };
  },
  data: function () {
    return {
      search: '', // current search input
      availableSearchItems: [] as Array<ImageKeyword>, // holds all keywords for searchbar, TODO add also categories and image names...
      searchItems: [] as Array<ImageKeyword>, // holds searchable words that match the input in searchbar
      isAutocompleteActicated: true // true when autocomplete is shown according to this.search, false when for example user clicked on an item
    };
  },
  mounted() {
    // load keywords
    this.availableSearchItems = imageData.searchWords;

    emitter.on('clearSearch', () => {
      this.clearSearch();
    });

    emitter.on('resetAutocompleteList', () => this.resetAutocompleteList());

    emitter.on('onHomeClicked', () => {
      // user clicked on home on desktop
      if (this.search != '') {
        this.clearSearch();
      }
      this.resetAutocompleteList();
    });

    emitter.on('onCategoryClicked', () => {
      console.log('category clicked');
      // category clicked in menu
      if (this.search) {
        this.clearSearch();
      }
    });
  },
  unmounted() {
    // unsubscribe from events
    emitter.off('clearSearch');
    emitter.off('resetAutocompleteList');
    emitter.off('onHomeClicked');
    emitter.off('onCategoryClicked');
  },
  methods: {
    translate(keyword: ImageKeyword) {
      return (keyword.name[this.$i18n.locale] as TranslationObject).text;
    },
    /**
     * User clicked to return home.
     * Resets category selection and cancel search if needed.
     */
    homeClicked() {
      if (this.search != '') {
        console.log('clear!');
        this.clearSearch();
      }
      this.$router.push({path: '/'}).catch((error) => {
        console.error('Error pushing route to root: ', error);
      });
      this.resetAutocompleteList();
      emitter.emit('onHomeClicked');
    },
    /**
     * Handles change of search
     */
    searchChange() {
      console.log('Search text: ' + this.search);
      if (this.search === '') {
        console.warn('Search is empty.');
        this.resetAutocompleteList();
      } else if (this.search !== '') {
        emitter.emit('onSearch'); // TODO only necessary to emit at start of search, to prevent to many events

        // determine which items to show in autocomplete list
        if (this.search && this.search.trim() !== '' && this.isAutocompleteActicated) {
          //only show suggestions after specified limit
          if (this.search.length >= AUTOCOMPLETE_SEARCH_MINIMUM) {
            const lowerCaseSearch = this.search.toLowerCase();

            // filter for matching search entries
            this.searchItems = this.availableSearchItems.filter((keyword) => {
              const lowerCaseKeyword = (keyword.name[this.$i18n.locale] as TranslationObject).text.toLowerCase();
              return lowerCaseKeyword.indexOf(lowerCaseSearch) > -1;
            });

            // sort result after better matching for search term
            this.searchItems.sort((a, b) => {
              const translatedA = this.translate(a);
              const translatedB = this.translate(b);
              if (translatedA.toLowerCase() == lowerCaseSearch) {
                // search term is same as image name of a
                return -1;
              } else if (translatedA.toLowerCase() > translatedB.toLowerCase()) {
                return 1;
              } else if (translatedA.toLowerCase() < translatedB.toLowerCase()) {
                return -1;
              } else {
                return 0;
              }
            });
          }
        } else {
          this.searchItems = [];
        }
      }
    },
    /**
     * Handle click on search word in autocomplete list.
     */
    clickedSearchWord(word: ImageKeyword) {
      if (!this.$route.path.includes('images')) {
        console.log('Search should be on image pages...');
        this.$router.push({path: '/search/'}).catch((error) => {
          console.error('Cannot push to search: ', error);
        });
        setTimeout(() => {
          // wait a bit until redirect happened
          this.isAutocompleteActicated = false;
          this.search = this.translate(word);
          this.navigateToSearchView();
          emitter.emit('filterImagesBySearchInput', this.search);
        }, 10);
      }
      this.isAutocompleteActicated = false;
      this.search = this.translate(word);
      this.navigateToSearchView();
      emitter.emit('filterImagesBySearchInput', this.search);
    },
    /**
     * Clicked x on searchbar
     */
    clickedSearchCancel() {
      emitter.emit('searchCancelled');
      this.clearSearch();
    },
    /**
     * Closes autocomplete list.
     */
    resetAutocompleteList() {
      this.isAutocompleteActicated = true;
      this.searchItems = [];
    },
    /**
     * User focused search to put in input.
     */
    searchFocused() {
      this.isAutocompleteActicated = true; // activate again when user wants to enter search words again
      emitter.emit('onSearchFocused');
    },
    /**
     * Handle Enter key in search input.
     */
    // eslint-disable-next-line
    onSearchEnter(event: any) {
      // return to images page if we should be somewhere else
      if (!this.$route.path.includes('images')) {
        console.log('Search should be on image pages...');
        this.$router.push({path: '/search/'}).catch((error) => {
          console.error('Cannot push to search: ', error);
        });
        setTimeout(() => {
          // wait a bit until redirect happened
          // hide list and do the search
          this.resetAutocompleteList();
          this.navigateToSearchView();
          emitter.emit('filterImagesBySearchInput', this.search);
        }, 10);
      }

      if (this.search == 'sesam öffne dich') {
        // show some admin stuff
        emitter.emit('housekeepingActicated');
      } else {
        // hide list and do the search
        this.resetAutocompleteList();
        this.navigateToSearchView();
        emitter.emit('filterImagesBySearchInput', this.search);
      }
    },
    /**
     * User clicked on translation button.
     */
    clickedTranslation() {
      emitter.emit('translationVisible');
    },
    /**
     * Search is cleared.
     */
    clearSearch() {
      if (this.search != '') {
        this.search = '';
        this.resetAutocompleteList();
        this.searchChange();
      }
    },
    /**
     * Navigates to search route
     */
    navigateToSearchView() {
      emitter.emit('showSearchResults');
      this.$router.push('/search/').catch((error) => {
        console.error('Cannot push router to search: ', error);
      });
    }
  }
});


import {defineComponent} from 'vue';
import {emitter} from '@/main';

export default defineComponent({
  name: 'BackButton',
  data: function () {
    return {
      isVisible: false // true when button is visible
    };
  },
  watch: {
    /**
     * Watch route param id to recognize when category changed.
     */
    '$route.params.imageId': {
      handler: function (imageId: string) {
        if (imageId) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    emitter.on('onSetBackButtonVisibility', (toggle: boolean) => {
      this.isVisible = toggle;
    });
  },
  methods: {
    backButtonClicked() {
      emitter.emit('onBackButton');
    }
  },
  unmounted() {
    // unsubscribe from events
    emitter.off('onSetBackButtonVisibility');
  }
});


import {IonCard, IonCardContent, IonInput, IonIcon} from '@ionic/vue';
import {eyeOutline, eyeOffOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {emitter} from '@/main';
import ACCEPTED_LOGINS from '../assets/acceptedLogins.json';
import {LoginType, StorageItems} from '@/model/interfaces';

export default defineComponent({
  name: 'LoginComponent',
  components: {
    IonCard,
    IonCardContent,
    IonInput,
    IonIcon
  },
  setup() {
    return {
      eyeOutline,
      eyeOffOutline
    };
  },
  data: function () {
    return {
      password: '', // text for password
      matomoId: '', // ID transmitted to Matomo – to be able to differentiate client's usage
      acceptedLogins: ACCEPTED_LOGINS as LoginType[], // list of accepted passwords
      passwordWrongTry: false, // true when tried a wrong password
      hidePassword: true // true when password is hidden
    };
  },
  methods: {
    /**
     * User clicked on login button or submitted form.
     * @param e Event
     */
    login(e: Event) {
      e.preventDefault();
      this.passwordWrongTry = false;
      this.resetAnimation();
      const login = this.acceptedLogins.find((login) => login.password === this.password);
      if (login && login.password == this.password) {
        const parsed = JSON.stringify(true);
        localStorage.setItem(StorageItems.IS_USER_LOGGED_IN, parsed);
        emitter.emit('onLoginSuccess');
      } else {
        const parsed = JSON.stringify(false);
        localStorage.setItem(StorageItems.IS_USER_LOGGED_IN, parsed);
        this.passwordWrongTry = true;
      }
      localStorage.setItem(StorageItems.MATOMO_ID, this.matomoId);
    },
    /**
     * Reset wrong password animation.
     */
    resetAnimation() {
      const el = document.getElementById('loginCard') as HTMLElement;
      el.style.animation = 'none';
      el.offsetHeight; /* trigger reflow */
      el.style.animation = '';
    }
  }
});
